<template>
	<iframe title="libreoffice-iframe" allowfullscreen :src="url" style="height: 100%; width: 100%" />
</template>

<script>
import DocumentEditionModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentEditionModuleGuard'

export default {
	name: 'LibreOfficeViewer',
	mixins: [DocumentEditionModuleGuard],
	props: {
		document: {
			required: true,
			type: Object
		},
		src: {
			required: true,
			type: String
		},
		version: {
			required: false,
			type: Object,
			default: null
		},
		readOnlyMode: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	data: function () {
		return {
			url: ''
		}
	},
	watch: {
		document: {
			handler: function () {
				this.retrieveLibreOfficeUrl()
			},
			immediate: true
		},
		version: {
			handler: function () {
				this.retrieveLibreOfficeUrl()
			},
			immediate: true
		}
	},
	methods: {
		retrieveLibreOfficeUrl: function () {
			this.service.getDocumentEditionOnlineEditorURL(this.vendorId, this.document, this.version, this.readOnlyMode).then(res => {
				this.url = res.url
			})
		}
	}
}
</script>
